<template>
  <div id="CeasarsPalace">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getCaesarsPalaceMapData } from "@/data/sectionData/whereWeAreData/caesarsPalaceMapData"

export default {
  name: "CeasarsPalace",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getCaesarsPalaceMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>