export const getCaesarsPalaceMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-caesars-route.jpg'),
            mapLine: 'CaesarsPalacePath',
            venueBg:  require('@/assets/maps/caesars.png'),
            venue: 'Caesars Palace',
            distance: '2.7',
            singleLineVenue: false,
            threeLineVenue: false
        }
    ]
}
